import React from 'react'
import { useParams } from "react-router-dom"
import ReactPlayer from 'react-player/lazy'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

function Videoplayer() {

  const videoUrl = "https://www.youtube.com/embed/dQw4w9WgXcQ"
  const [show, setShowDetails] = React.useState([])
  return (
    <div>


      {show.map((content) =>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>NRTV - Watch {content.contentTitle}</title>
          </Helmet>

          <iframe width="100" height="100" src="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
          encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
      )}
    </div>
  )
}

export default Videoplayer