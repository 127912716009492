import React from 'react'
import NavBar from '../components/landingComponents/NavBar'
import CallToAction from '../components/landingComponents/CallToAction'
import DownloadMobileApp from '../components/landingComponents/DownloadMobileApp'
import Footer from '../components/landingComponents/Footer'
import TrendingNews from '../components/landingComponents/TrendingNews'
import WatchTVCategories from '../components/landingComponents/WatchTVCategories'
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import TopRectangleAd from '../components/landingComponents/topRectangleAd'
import { useLocation } from "react-router-dom";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Link } from 'react-router-dom'

function Index() {
  const [showModal, setShowModal] = React.useState(false)

  const closeHandler = () => {
    setShowModal(true);
  };

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <section>
      <Helmet>
        <meta charSet="utf-8" />
        <title>NRTV - Home</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <NavBar />

      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={showModal}
        onClose={closeHandler}
        width="80vh"
      >
        <Modal.Body>
          <div className="videoDiv">
            <h2 className='text-center mb-4 font-bold'>
            Breadbasket : 28 September 2023
            </h2>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/b2LW13oKR5c" className='hidden md:block px-4'
              title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
              encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
            <iframe width="334" height="315" src="https://www.youtube.com/embed/b2LW13oKR5c" className='d:block md:hidden'
              title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
              encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
          </div>

          {/* btns */}
          <div className="space-y-5 sm:space-y-6 mb-5">
            <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 lg:grid-cols-2 my-4">
              <a href="https://www.youtube.com/embed/b2LW13oKR5c" target="_blank" class="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all
               duration-200 bg-gray-900 border border-transparent rounded hover:bg-gray-600 font-pj">
                <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                </svg>
                <button
                  type="button"
                >
                  VIEW FULL SCREEN
                </button>
              </a>

              {/* add new product */}
              <button
                type="button"
                onClick={()=>{ setShowModal(false)}}
                class="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white transition-all 
                duration-200 bg-gray-900 border border-transparent rounded hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                CLOSE
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <CallToAction />/

      {/* watch tv categories */}
      <WatchTVCategories />

      {/* trending news higlights */}
      <TrendingNews />

      <DownloadMobileApp />
      <Footer />
    </section>
  )
}

export default Index