import React from 'react'

function topRectangleAd() {
    return (
        <div className="bg-black topRectangleAd font-bold text-center my-10">
            <h5 className='text-white'>Advertise Here</h5>
        </div>
    )
}

export default topRectangleAd